import React, { useState } from "react";

import styled from "styled-components";
import CheckoutStep from "./CheckoutStep";
import YearlyPlan from "./YearlyPlan";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

export const Container = styled.div`
  width: 40%;
  height: 100%;
  form {
    display: flex;
    flex-direction: column;
    border: 0.4px solid #989898;
    border-radius: 4px;
    padding: 20px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
  form input {
    padding: 20px 10px;
    background: inherit;
    outline: none;
    border: none;
    border-bottom: 0.4px solid #989898;
    font-family: sans-serif;
    color: white;
  }
  form input:last-child > * {
    border-bottom: 0px solid white;
  }
  form textarea {
    padding: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    background: inherit;
    outline: none;
    border: none;
    border-bottom: 0.4px solid #989898;
    font-family: sans-serif;
    color: white;
  }
  form input::placeholder,
  form textarea::placeholder {
    color: white;
  }
  .subscriptionplan {
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 17px 20px;
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(100px);
    border-radius: 15px;
  }
  .header {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #b3b3b3;
  }
  .header::first-letter {
    text-transform: capitalize;
  }
  .subscriptionplan h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    margin-top: 8px;
    font-size: 48px;
    color: #ffffff;
  }
  .price-info {
    display: flex;
    margin-top: 16px;
    gap: 8px;
    flex-direction: row;
    justify-content: space-between;
  }
  .price-info p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
  }
  .form-border {
    border: 0.2px solid #ffffff;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .error {
    color: red;
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    font-weight: 800;
  }
  .checkout-button {
    cursor: pointer;
    margin: auto;
    margin-top: 40px;
    width: max-content;
  }
  .checkout-button p {
    padding: 12px 60px;
    background: #ffffff;
    border-radius: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #000000;
  }
  .footer-text {
    margin-top: 30px;
    font-family: "Futura Bk BT";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #989898;
  }
  .checkout-step {
    display: none;
  }
  .yearly-plan-section {
    display: none;
  }
  .otp-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 20px;
  }
  .otp-input-box {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 10px;
  }
  .otp-input-box button {
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 15px 30px;
    font-weight: 500;
    border-radius: 6px;
  }
  .otp-input-box input {
    width: 70%;
    color: #fff;
    border: none;
    padding: 15px 12px;
    background: #2b2b2b;
    border-radius: 6px;
  }

  *:focus {
    outline: none;
  }
  ${
    "" /* span.textOpt {
    color: #cbcbcb;
    font-family: "Poppins";
    font-style: normal;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 0px solid white;
    padding: 
  } */
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .phone-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.4px solid #989898;
  }
  .phone-container input {
    border: none;
  }
  .textOpt {
    color: #cbcbcb;
    font-family: "Poppins";
    font-style: normal;
    font-size: 12px;
    cursor: pointer;
  }
  @media (max-width: 800px) {
    width: 100%;
    .checkout-step {
      display: block;
      margin-bottom: 40px;
    }
    .subscriptionplan {
      margin-top: 30px;
    }
    .subscriptionplan h1 {
      font-size: 32px;
    }
    .price-info p {
      font-size: 12px;
    }
    .form-border {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .error {
      font-size: 12px;
    }
    .checkout-button {
      margin-top: 30px;
    }
    .checkout-button p {
      padding: 10px 40px;
    }
    .footer-text {
      margin-top: 30px;
      font-size: 10px;
    }
    .yearly-plan-section {
      display: block;
      margin-top: 40px;
    }
  }
`;

export default function CheckoutForm() {
  let navigate = useNavigate();
  let { planName } = useParams();
   if (planName != "yearly" && planName != "monthly") {
    planName = "yearly";
  }


  const buyPlanList = {
    yearly: {
      price: 1549,
      cancelPrice: "3600",
      planType: "Yearly Plan",
      featurePoint: [
        "25 AI Stock Report",
        "12 AI Portfolio",
        "TradeBunch access",
        "24x7 Whatsapp, Email & Call Support",
        "Daily Delivery Calls",
        "Index and Stock Options",
      ],
      navigate: () => {
        navigate("/checkout/yearly");
      },
    },
    monthly: {
      price: 299,
      planType: "Monthly Plan",
      cancelPrice: "499",
      featurePoint: [
        "2 AI Stock Report",
        "2 AI Portfolio",
        "TradeBunch access",
        "24x7 Whatsapp, Email & Call Support",
        "Daily Delivery Calls",
        "Index and Stock Options",
      ],
      navigate: () => {
        navigate("/checkout/monthly");
      },
    },
  };




  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  const [confirm, setConfirm] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);

  const [otp, setOtp] = useState("");

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    if (phone.length == 10) {
      sendOTP();
    }
  }, [phone]);

  async function sendOTP() {
    console.log("CALLED");
    axios.post("/sendOtp", {
      phone: phone,
    });
  }

  async function displayRazorpay(amount) {
    if (fullName === "" || (phone === "" && email === "")) {
      setError("Please Enter Details");
      setTimeout(() => {
        setError("");
      }, 3000);
    } else if (confirm === false) {
      setError("Please verify first");
      setTimeout(() => {
        setError("");
      }, 3000);
    } else {
      axios
        .post("/createUser", {
          fullname: fullName,
          phone: phone,
          otp: otp,
          email: email,
          address: address,
          state: state,
          pincode: pin,
        })

        .then(
          async (response) => {
            const res = loadScript(
              "https://checkout.razorpay.com/v1/checkout.js"
            );

            if (!res) {
              alert("Razorpay SDK failed to load. Are you online?");
              return;
            }

            await axios
              .post("/razorpay", {
                amount: amount,
                planPrice: buyPlanList[planName]["price"],
                planName:planName,
                userId: response.data?.data,
              })
              .then((res) => {
                console.log(res);
                const options = {
                  key: "rzp_live_QArphLKabHkegp",
                  currency: res.data.data.currency,
                  amount: amount,
                  order_id: res.data.data.id,
                  name: `${fullName}`,
                  description: "Thank you",
                  callback_url: `//localhost:80/verification`,
                  prefill: {
                    name: `${fullName}`,
                    email: `${email}`,
                    phone_number: `${phone}`,
                  },
                };

                localStorage.setItem("fullname", `${fullName}`);
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
              });
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }



  async function submitRequest() {
    axios
      .post("/verify", {
        phone: phone,
        otp: otp,
      })
      .then((e) => {
        setApiCalled(true);
        if (e.data.status) {
          setConfirm(true);
        } else {
          setConfirm(false);
        }
      });
  }

  return (
    <Container>
      <div className="checkout-step">
        <CheckoutStep />
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          placeholder="Full Name"
          required
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />

        <div className="phone-container">
          <input
            type="number"
            placeholder="Phone No"
            disabled={apiCalled && confirm}
            required
            max={10}
            value={phone}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setPhone(e.target.value);
              }
            }}
          />

          {phone.length == 10 && !confirm ? (
            <span className="textOpt" onClick={() => sendOTP()}>
              resend OTP
            </span>
          ) : (
            <></>
          )}
        </div>

        <input
          type="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <input
          placeholder="State , Region & City"
          required
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <input
          type="number"
          placeholder="Zip / Postal Code"
          required
          value={pin}
          onChange={(e) => {
            if (e.target.value.length <= 6) {
              setPin(e.target.value);
            }
          }}
        />
      </form>

      {phone.length == 10 ? (
        <div className="otp-container">
          <div className="otp-input-box">
            <input
              style={{
                background: apiCalled
                  ? confirm
                    ? "rgba(0, 225, 64, 0.15)"
                    : "rgba(255, 26, 26, 0.2)"
                  : "",
              }}
              placeholder="Enter OTP"
              type="number"
              required
              disabled={apiCalled && confirm}
              value={otp}
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  setOtp(e.target.value);
                }
              }}
            />
            <button
              style={{
                background: "#31cb53",
              }}
              onClick={() => {
                if ((!apiCalled && !confirm) || (apiCalled && !confirm))
                  submitRequest();
              }}
            >
              {apiCalled ? (confirm ? "Confirmed" : "Retry") : "Confirm"}
            </button>
          </div>
        </div>
      ) : (
        <> </>
      )}

      {planName == "yearly" ? (
        <></>
      ) : (
        <div className="yearly-plan-section">
          <YearlyPlan />
        </div>
      )}

      <div className="subscriptionplan">
        <p className="header">{planName} Subscription</p>
        <h1>{buyPlanList[planName]["price"]} INR</h1>

        <div className="price-info">
          <p>Subtotal</p>
          <p>₹{buyPlanList[planName]["price"]}</p>
        </div>
        <div className="price-info">
          <p>18% VAT/GST</p>
          <p>₹{(buyPlanList[planName]["price"] * 18) / 100}</p>
        </div>
        <div className="form-border"></div>
        <div className="price-info">
          <p>Total</p>
          <p>
            ₹
            {(buyPlanList[planName]["price"] * 18) / 100 +
              buyPlanList[planName]["price"]}
          </p>
        </div>

        <p className="error">{error}</p>

        <div
          className="checkout-button"
          onClick={() =>
            displayRazorpay(
              (buyPlanList[planName]["price"] * 18) / 100 +
                buyPlanList[planName]["price"]
            )
          }
        >
          <p>Complete Checkout</p>
        </div>
        <p className="footer-text">
          Secure checkout. For your convenience Tradebook will store your
          encrypted payment information for future orders. Manage your payment
          information in your Account Details.
        </p>
      </div>
    </Container>
  );
}
