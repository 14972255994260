import React, { useState } from "react";
import styled from "styled-components";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";

const ExpandedListContainer = ({ data }) => {
  const [expand, setexpand] = useState(false);

  return (
    <>
      <div className="expand-heading" onClick={() => setexpand(!expand)}>
        {expand ? (
          <AiFillCaretDown color="white" />
        ) : (
          <AiFillCaretRight color="white" />
        )}
        <p>{data.question}</p>
      </div>

      <div
        className="expand-content-container"
        style={{ display: expand ? "block" : "none" }}
      >
        <p className="expand-content">- {data.faqAnswer}</p>
      </div>
    </>
  );
};

export const Container = styled.div`
  margin: auto;
  margin-top: 120px;
  width: 80%;
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #d8d8d8;
  }

  .expand-heading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .expand-heading p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
  }
  .expand-heading svg {
    font-size: 22px;
    margin-right: 5px;
  }

  .expand-content {
    font-family: "Poppins";
    font-style: normal;
    margin: 20px 20px;
    font-weight: 400;
    font-size: 24px;
    color: #969696;
  }

  .list-container {
    display: flex;
    flex-direction: column;
    margin: 30px 0px;
  }
  .faq-list {
    /* border-top: 0.4px solid #ffffff; */
    padding: 25px 0px;
    border-bottom: 0.4px solid #979797;
  }

  .faq-list:first-child {
    border-top: none;
  }

  @media (max-width: 800px) {
    margin-top: 40px;

    width: 90%;
    h1 {
      font-size: 2rem;
    }
    .expand-heading p {
      font-size: 14px;
    }
    .list-container {
      margin: 15px 0px;
    }
    .expand-heading svg {
      font-size: 16px;
    }
    .expand-content {
      font-size: 14px;
      margin: 10px 20px;
    }
  }
`;

export default function FaqContainer() {
  const [expand, setexpand] = useState(true);

  const faqList = [
    {
      question:
        "Q. What is Tradebook's AI advisory service for the stock market?",
      faqAnswer:
        "Tradebook's AI advisory service is a platform that uses advanced artificial intelligence algorithms to analyze market trends and provide personalized investment advice to its users.",
    },
    {
      question: "Q. How does Tradebook's AI advisory service work?",
      faqAnswer:
        "Tradebook's AI advisory service uses cutting-edge technology to analyze vast amounts of market data and identify investment opportunities. It then provides users with personalized stock reports and portfolio management recommendations based on their investment goals and risk tolerance.",
    },
    {
      question:
        "Q: What are the benefits of subscribing to Tradebook's AI advisory service?",
      faqAnswer:
        "Subscribers to Tradebook's AI advisory service receive a range of benefits, including access to real-time market data, personalized investment advice, AI stock reports, AI portfolio management, and the best customer support.",
    },
    {
      question: "Q: How much does Tradebook's AI advisory service cost?",
      faqAnswer:
        "Tradebook offers both monthly and yearly subscription plans for its AI advisory service. The cost depends on the plan you choose and the level of access you require.",
    },
    {
      question: "Q: Is Tradebook's AI advisory service suitable for beginners?",
      faqAnswer:
        "Yes, Tradebook's AI advisory service is designed to be user-friendly and accessible to investors of all levels, including beginners. It provides personalized investment advice based on the user's risk tolerance and investment goals.",
    },
    {
      question:
        "Q: Can Tradebook's AI advisory service guarantee returns on investments?",
      faqAnswer:
        "No, Tradebook's AI advisory service cannot guarantee returns on investments. However, its advanced algorithms and personalized recommendations can help users make informed investment decisions and increase their chances of success in the stock market.",
    },
    {
      question:
        "Q: What is the customer support like for Tradebook's AI advisory service?",
      faqAnswer:
        "Tradebook's AI advisory service offers the best customer support to its users, with dedicated customer service representatives available to answer any questions and address any concerns.",
    },
  ];

  return (
    <Container>
      <h1>FAQ’s</h1>

      <div className="list-container">
        {faqList.map((e) => (
          <div className="faq-list">
            <ExpandedListContainer data={e} />
          </div>
        ))}
      </div>
    </Container>
  );
}
