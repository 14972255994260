import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
  margin-top: 140px;
  margin-bottom: 20px;

  .about-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .about-us {
    width: 50%;
  }
  .contact-us {
    width: 40%;
  }
  .about-us h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #c1c1c1;
  }
  .contact-us h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #c1c1c1;
  }
  .about-us p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    margin-top: 30px;
    font-size: 18px;
    line-height: 200.37%;
    color: #969696;
  }

  .email-input-box {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    width: 100%;
    margin-top: 40px;
  }
  .email-input-box input {
    color: #fff;
    border: none;
    width: 60%;
    padding: 15px 12px;
    background: #2b2b2b;
    border-radius: 6px;
  }
  .email-input-box input:focus {
    outline: none;
  }
  .email-input-box button {
    color: #fff;
    background-color: #31cb53;
    outline: none;
    width: max-content;
    border: none;
    cursor: pointer;
    padding: 15px 30px;
    font-weight: 500;
    border-radius: 6px;
  }
  .social-media-container {
    display: flex;
    gap: 35px;
    margin-top: 40px;
    height: max-content;
  }
  .social-media-container img {
    height: 50px;
    cursor: pointer;
  }

  .float-container {
    position: fixed;
    right: 80px;
    bottom: 50px;
  }
  .float-container img {
    height: 50px;
    cursor: pointer;
  }
  .privacy-link-container {
    margin-top: 60px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .privacy-link-container p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #c1c1c1;
  }

  .about-us-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    gap: 20px;
  }
  .about-us-2 img {
    width: 90px;
    height: 60px;
  }
  .about-us-2 p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #c1c1c1;
  }

  .download-button {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 40%;
    padding: 15px 20px;
    border: 1px solid #e9e9e9;
    color: #ffffff;
    border-radius: 21px;
  }
  .download-button p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
  }

  .disclaimer-container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .disclaimer-container p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 133.37%;
    text-align: center;
    color: #868686;
  }

  .disclaimer-container h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 133.37%;
    color: #868686;
  }

  .copyright {
    margin-top: 80px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #bababa;
    text-align: center;
  }

  @media (max-width: 800px) {
    width: 90%;
    margin: auto;
    margin-top: 60px;

    .about-container {
      flex-direction: column;
    }
    .about-us {
      width: 100%;
    }
    .contact-us {
      width: 100%;
      margin-top: 40px;
    }

    .about-us p {
      font-size: 14px;
      margin-top: 12px;
    }
    .social-media-container img {
      height: 36px;
    }

    .float-container {
      right: 30px;
      bottom: 30px;
    }
    .float-container img {
      height: 36px;
    }

    .privacy-link-container {
      margin-top: 40px;
      justify-content: start;
      gap: 20px;
    }
    .privacy-link-container p {
      font-size: 10px;
    }
    .about-us-2 {
      margin-top: 80px;
    }
    .about-us-2 p {
      font-size: 14px;
    }

    .download-button {
      width: 80%;
      padding: 10px 20px;
    }

    .download-button p {
      font-size: 12px;
    }

    .disclaimer-container {
      gap: 10px;
    }
    .disclaimer-container h1 {
      font-size: 24px;
    }
    .disclaimer-container p {
      font-size: 14px;
    }

    .copyright {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
`;

export default function AboutUs() {
  let navigate = useNavigate();

  const [phone, setphone] = useState("");
  const [submitted, setSubmitted] = useState(false);

  function submitRequest() {
    if (phone != "") {
      axios.post("/callrequest", { phone: phone }).then((res) => {
        setphone("");
        window.alert("request send");
        setSubmitted(true);
      });
    }
  }

  return (
    <Container>
      <div className="about-container">
        <div className="about-us">
          <h1>About Us</h1>
          <p>
            The Tradebook Consultancy Private Limited, a Company incorporated
            under the provisions of the Companies Act, 2013, is one of the
            prominent research house providing services of equity research,
            recommendation and advisory services in the financial markets under
            its brand name “TradeBook”. The Tradebook is registered with SEBI
            and hold SEBI license as a Research Analyst (Application No 996531).
            The Tradebook provides research, recommendation and advisory
            services for a broad array of investors including NRIs, HNI’s, and
            thousands of individual investors.
          </p>
        </div>
        <div className="contact-us">
          <h1>Contact Us</h1>
          <div className="email-input-box">
            <input
              placeholder="Your phone number"
              type="number"
              value={phone}
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  setphone(e.target.value);
                }
              }}
            />
            <button onClick={() => submitRequest()}>
              {submitted ? "Submitted" : "Submit"}
            </button>
          </div>
          <div className="social-media-container">
            <img
              src="/whatsapp.png"
              alt="instagram"
              onClick={() => window.open("https://wa.me/9324050371", true)}
            />
            <img
              src="/instagram.png"
              alt="instagram"
              onClick={() =>
                window.open(
                  "https://instagram.com/tradebook.in?igshid=YmMyMTA2M2Y=",
                  true
                )
              }
            />
            <img
              src="/twitter.png"
              alt="twitter"
              onClick={() =>
                window.open("https://twitter.com/Tradebook_in", true)
              }
            />
            <img
              src="/linkdln.png"
              alt="linkdln"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/tradebook-consultancy-services/",
                  true
                )
              }
            />
	    <div
		class="fb-like"
  		data-share="true"
  		data-width="450"
  		data-show-faces="true">
	    </div>
          </div>
          <div className="privacy-link-container">
            <p onClick={() => navigate("/privacy-policy")}>Privacy Policy</p>
            <p onClick={() => navigate("/terms-condition")}>
              Terms & Condition
            </p>
          </div>
        </div>
      </div>
      <div className="about-us-2">
        <img src="/logo.png" alt="TradeBook" />
        <p>
          Tradebook Consultancy Private Limited. 413, Swastik Chambers, SG Barve
          Marg, Mumbai – 400 021
          <br /> Telephone: +91 93240 50371
          <br /> Email: support@tradebook.in.net
          <br /> GSTIN : 27AAICT9400C1ZH{" "}
        </p>
      </div>

      <div
        className="download-button"
        onClick={() => {
          const platform = window.navigator.platform.toLocaleLowerCase();

          if (platform.includes("mac") || platform.includes("ip")) {
            window.open(
              "https://apps.apple.com/in/app/tradebook-one-stop-solution/id1659276893",
              true
            );
          } else {
            window.open(
              "https://play.google.com/store/apps/details?id=com.we3.tradebook.app",
              true
            );
          }
        }}
      >
        <p>DOWNLOAD TRADEBOOK APP</p>
        <img src="/playstore.png" alt="tradebook play store" />&
        <img src="/apple.png" alt="tradebook app store" />
      </div>

      <div className="disclaimer-container">
        <h1>Disclaimer</h1>
        <p>
          Investments in securities market are subject to market risks, read all
          the related documents carefully before investing. Brokerage will not
          exceed SEBI prescribed limit. Please refer the Risk Disclosure
          Document issued by SEBI and go through the Rights and Obligations and
          Do’s and Don’ts issued by Stock Exchanges and Depositories before
          trading on the Stock Exchanges. For commodities derivatives please
          note that Commodities Derivatives are highly leveraged instruments.
          PMS is not offered in Commodity Derivative Segment by Tradebook.
          Before investing the asset class consider your investment objectives,
          level of experience and risk appetite carefully.
        </p>
        <p>
          MCX - MCX / AP / 152607
          <br /> BSE : AP01061201141869 <br />
          NSE : AP0397384703 <br />
          GST : 27AAICT9400C1ZH
          <br /> PAN NO : AAICT9400C <br />
          SEBI Application : 996531 <br />
          CIN : U67100MH2021PTC369735
        </p>
      </div>
      <p className="copyright">
        @Copyright 2021 Tradebook Consultancy Service Pvt.Ltd
      </p>

      <div className="float-container">
        <img
          src="/whatsapp.png"
          alt="instagram"
          onClick={() => window.open("https://wa.me/9324050371", true)}
        />
      </div>
    </Container>
  );
}
