import React from "react";

import styled from "styled-components";

export const Container = styled.div`
  .checkout-status {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
  }
  .checkout-status div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .stage1 div {
    width: max-content;
    background: #ffffff;
    border-radius: 50%;
  }
  .stage1 div > p:first-child {
    padding: 6px 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13.005px;
    color: #182047;
  }
  .stage1 p:last-child {
    font-family: "Poppins";
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 13.005px;
    color: #ffffff;
  }
  /* stage 2 */

  .stage2 div > p:first-child {
    padding: 8px 12px;
    font-weight: 700;
    font-size: 13.005px;
    line-height: 15px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    color: #ffffff;
  }
  .stage2 p:last-child {
    font-weight: 700;
  }
  /* stage 3 */

  .stage3 div > p:first-child {
    padding: 8px 12px;
    border: 1px solid #727272;
    border-radius: 50%;
    font-weight: 700;
    font-size: 13.005px;
    line-height: 15px;
    color: #727272;
  }
  .stage3 p:last-child {
    font-weight: 700;
    color: #727272;
  }

  .border {
    width: 60px;
    border-top: 0.8px solid #ffffff;
  }
  .border-dashed {
    width: 60px;
    border-top: 0.8px solid #ffffff;
  }
  .border-dashed {
    width: 60px;
    border-top: 0.8px dashed #ffffff;
  }

  @media (max-width: 800px) {
    .checkout-status {
      gap: 10px;
    }
    .checkout-status div {
      gap: 2px;
    }
    .stage1 div > p:first-child {
      padding: 2px 6px;
      font-size: 8px;
    }
    .stage1 p:last-child {
      font-size: 12px;
      width: max-content;
    }
    /* stage 2 */
    /* .stage2 div {
      border: 1px solid #ffffff;
      border-radius: 50%;
    } */
    .stage2 div > p:first-child {
      padding: 2px 6px;
      font-size: 8px;
      border: 1px solid #ffffff;
      border-radius: 50%;
    }
    .stage2 p:last-child {
      font-size: 12px;
    }
    /* stage 3 */

    .stage3 div > p:first-child {
      padding: 2px 6px;
      border: 1px solid #727272;
      border-radius: 50%;
      font-size: 8px;
    }
    .stage3 p:last-child {
      font-size: 12px;
    }

    .border {
      width: 100%;
      border-top: 0.8px solid #ffffff;
    }
    .border-dashed {
      width: 100%;
      border-top: 0.8px solid #ffffff;
    }
  }
`;

export default function CheckoutStep() {
  return (
    <Container>
      <div className="checkout-status">
        <div className="stage1">
          <div>
            <p>1</p>
          </div>
          <p>View Cart</p>
        </div>
        <div className="border"></div>
        <div className="stage2">
          <div>
            <p>2</p>
          </div>
          <p>Checkout</p>
        </div>
        <div className="border-dashed"></div>
        <div className="stage3">
          <div>
            <p>3</p>
          </div>
          <p>Confirmation</p>
        </div>
      </div>
    </Container>
  );
}
