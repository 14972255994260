import React, { useState } from "react";
import styled from "styled-components";
import CheckoutStep from "./CheckoutStep";
import YearlyPlan from "./YearlyPlan";
import { useNavigate, useParams } from "react-router-dom";

export const Container = styled.div`
  width: 50%;
  .plan-header {
    background: #f7f7f7;
    backdrop-filter: blur(25px);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .plan-header p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    padding: 10px 20px;
    padding-bottom: 20px;
  }
  .plan-content {
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(100px);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: -10px;
  }

  ul {
    padding: 20px 40px;
    text-align: start;
    list-style: inside;
  }
  li {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 198.37%;
    color: #c8c8c8;
  }

  .checkout-step {
    display: block;
    margin-top: 60px;
  }
  .yearly-plan-section {
    display: block;
    margin-top: 40px;
  }

  @media (max-width: 800px) {
    width: 100%;
    .plan-header p {
      font-size: 14px;
    }
    ul {
      padding: 15px 20px;
    }
    li {
      font-size: 12px;
    }

    .yearly-plan-section {
      display: none;
    }
    .checkout-step {
      display: none;
    }
  }
`;

export default function PlanIntro() {
  let navigate = useNavigate();
  let { planName } = useParams();

  if (planName != "yearly" && planName != "monthly") {
    planName = "yearly";
  }

  const list = [
    "Launch a thriving trading career without spending hours on research and analysis.",
    "Spend less time and make more profits in trading.",
    "Transform from a beginner into a professional trader.",
    "Gain knowledge to deal with volatile markets",
    "Get access to the top 1% of traders.",
    "Get access to the tools that you need to trade like a pro.",
    "TradeBook is the one app to replace them all. With our comprehensive platform, you'll have everything you need to make informed trades and increase your profits. You can even track your progress and see how you're performing against the top 1% of traders.",
  ];


  return (
    <Container>
      <div className="plan-header">
        <p>With TradeBook you can:</p>
      </div>

      <div className="plan-content">
        <ul>
          {list.map((e) => {
            return <li>{e}</li>;
          })}
        </ul>
      </div>
      {planName == "yearly" ? (
        <></>
      ) : (
        <div className="yearly-plan-section">
          <YearlyPlan />
        </div>
      )}
      <div className="checkout-step">
        <CheckoutStep />
      </div>
    </Container>
  );
}
