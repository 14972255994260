import React, { useState } from "react";
import styled from "styled-components";

import axios from "axios";

export const AppIntroductionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  margin-top: 80px;
  justify-content: space-between;
  height: 80vh;

  .app-introduction {
    margin-top: 30px;
    width: 40%;
  }

  .app-introduction h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    background: linear-gradient(
      113.61deg,
      #ffffff -4.35%,
      rgba(255, 255, 255, 0) 106.7%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .discription {
    margin-top: 30px;
    line-height: 133.37%;
    font-style: normal;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 28px;
    color: #676767;
  }

  .email-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 20px;
  }
  .email-container p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #c8c8c8;
  }
  .email-input-box {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 10px;
  }
  .email-input-box input {
    width: 60%;
    color: #fff;
    border: none;
    padding: 15px 12px;
    background: #2b2b2b;
    border-radius: 6px;
  }
  .email-input-box input:focus {
    outline: none;
  }
  .email-input-box button {
    color: #fff;
    background-color: #31cb53;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 15px 30px;
    font-weight: 500;
    border-radius: 6px;
  }

  /* Image container */
  .tradebook-logs-container {
    height: 100%;
  }
  .tradebook-logs-container img {
    height: 100%;
  }

  @media (max-width: 800px) {
    flex-direction: row;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    height: 100%;
    flex-direction: column;

    .app-introduction {
      margin-top: 0px;
      padding: 0px 20px;
      width: 90%;
    }
    .app-introduction h1 {
      font-size: 48px;
      font-weight: bold;
    }
    .discription {
      margin-top: 15px;
      font-size: 20px;
    }
    .email-container {
      margin-top: 30px;
      gap: 20px;
    }
    .email-input-box {
      flex-direction: column;
      gap: 10px;
    }
    .email-input-box input {
      width: 80%;
    }
    .email-input-box button {
      width: fit-content;
    }

    .tradebook-logs-container {
      position: absolute;
      top: 50%;
      z-index: -1;
      opacity: 0.3;
    }
    .tradebook-logs-container img {
      width: 100vw;
      height: auto;
    }
  }
`;

export default function AppIntro() {
  const [phone, setphone] = useState("");
  const [submitted, setSubmitted] = useState(false);

  function submitRequest() {
    if (phone != "") {
      axios.post("/callrequest", { phone: phone }).then((res) => {
        setphone("");
        window.alert("Request sent");
        setSubmitted(true);
      });
    }
  }

  return (
    <>
      <AppIntroductionContainer>
        <div className="app-introduction">
          <h1>One app to replace all Investing apps</h1>
          <p className="discription">
            Use Tradebook AI to make smarter money decisions & change the way
            you invest and trade.
          </p>
          <div className="email-container">
            <p>Know more about TradeBook Plans</p>
            <div className="email-input-box">
              <input
                placeholder="Your phone number"
                type="number"
                value={phone}
                min={10}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setphone(e.target.value);
                  }
                }}
              />
              <button onClick={() => submitRequest()}>
                {submitted ? "Submitted" : "Submit"}
              </button>
            </div>
          </div>
        </div>

        <div className="tradebook-logs-container">
          <img src="tradebookLogos.png" alt="intro logo" />
        </div>
      </AppIntroductionContainer>
    </>
  );
}
