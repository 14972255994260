import React from "react";

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin: auto;
  width: 80%;
  margin-top: 140px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
  }
  img {
    margin-top: 40px;
    width: 80px;
    height: 100px;
  }

  @media (max-width: 800px) {
    margin: auto;
    width: 90%;
    margin-top: 80px;
    padding: 0px 0px;

    div {
      width: max-content;
    }

    p {
      font-size: 12px;
      text-align: center;
    }
    img {
      margin-top: 15px;
      width: 45px;
      height: 65px;
    }
  }
`;

export default function FeaturesIntro() {
  return (
    <Container>
      <div>
        <p>Personalized AI Portfolio</p>
        <img src="/report.png" alt="report" />
      </div>
      <div>
        <p>Equity Report</p>
        <img src="/eq report.png" alt="eq report" />
      </div>
      <div>
        <p>Quality Advice</p>
        <img src="/advice.png" alt="advice" />
      </div>
      <div>
        <p>Exclusive TradeBunches</p>
        <img src="/box.png" alt="box" />
      </div>
    </Container>
  );
}
