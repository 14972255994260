import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  margin-top: 45px;
  padding: 0px 20px;
  align-items: end;

  .header-logo-container {
    height: 100%;
    display: flex;
    gap: 7px;
    align-items: end;
  }
  .header-logo-container img {
    height: 4vh;
  }
  .header-logo-container h2 {
    font-family: "Poppins";
    line-height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #ffffff;
  }

  .header-link-container {
    width: 60%;
    font-family: "Poppins";
    cursor: pointer;
    /* height: 46%; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #6b6b6b;
    display: flex;
    justify-content: row;
    align-items: flex-end;
    gap: 40px;
  }

  .rate-application {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    width: max-content;
    color: #000000;
    background-color: #ffff;
    padding: 9px 16px;
    border-radius: 6px;
  }

  @media (max-width: 800px) {
    padding: 0px 0px;
    width: 98%;
    margin: auto;
    margin-top: 25px;

    .header-logo-container img {
      height: 16px;
    }
    .header-logo-container h2 {
      font-size: 1rem;
      padding-right: 10px;
    }
    .header-link-container {
      width: max-content;
      padding: 0px 4px;
      gap: 6px;
    }
    .header-link-container span {
      width: max-content;
      font-size: 10px;
    }
    .rate-application {
      padding: 2px 10px;
      font-size: 8px;
    }
  }
`;

export default function TopHeader() {
  let navigate = useNavigate();
  return (
    <HeaderContainer>
      <div className="header-logo-container" onClick={() => navigate("/")}>
        <img src="/logo.png" alt="TradeBook" />
        <h2>TradeBook</h2>
      </div>

      <div className="header-link-container">
        {/* <span onClick={()=>navigate('/checkout/yearly')}>View Yearly Plans</span> */}
        <span onClick={() => navigate("/checkout/yearly")}>Yearly Plans</span>
        <span onClick={() => navigate("/checkout/monthly")}>Monthly Plans</span>
      </div>

      <div
        className="rate-application"
        onClick={() => {
          const platform = window.navigator.platform.toLocaleLowerCase()

          if (platform.includes('mac') || platform.includes('ip')) {
            window.open(
              "https://apps.apple.com/in/app/tradebook-one-stop-solution/id1659276893",
              true
            )
          } else {
            window.open(
              "https://play.google.com/store/apps/details?id=com.we3.tradebook.app",
              true
            )
          }
        }
        }
      >
        Download Application
      </div>
    </HeaderContainer>
  );
}
