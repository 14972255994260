import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "material-icons/iconfont/material-icons.css";
import HomeScreen from "./screen";
import CheckoutPage from "./screen/Checkout";
import PrivacyPolicy from "./screen/PrivacyPolicy";
import ThankYouPage from "./screen/ThankYou";
import DownloadApplication from "./screen/Download";

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/checkout/:planName" element={<CheckoutPage />} />
        <Route path="/download" element={<DownloadApplication />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<PrivacyPolicy />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
      </Routes>
    </Router>
  );
};
export default App;
