import React from "react";
import AboutUs from "../component/AboutUs";
import AppIntro from "../component/AppIntro";
import FaqContainer from "../component/Faq";
import FeaturesIntro from "../component/FeaturesIntro";
import TopHeader from "../component/Header";
import Mockup from "../component/Mockup";
import OurPlans from "../component/OurPlan";
import StockIndexTable from "../component/StockIndexTable";
import Testimonials from "../component/Testimonials";

function HomeScreen() {
  return (
    <React.Fragment>
      <TopHeader />
      <AppIntro />
      <OurPlans />
      <StockIndexTable />
      <FeaturesIntro />
      <Mockup />
      <Testimonials />
      <FaqContainer />
      <AboutUs />
    </React.Fragment>
  );
}

export default HomeScreen;
