import React from "react";
import TopHeader from "../component/Header";

import styled from "styled-components";
import PlanIntro from "../component/checkout/PlanIntro";
import CheckoutForm from "../component/checkout/CheckoutForm";

export const Container = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 800px) {
    width: 90%;
    flex-direction: column-reverse;
  }
`;

function CheckoutPage() {
  return (
    <React.Fragment>
      <TopHeader />
      <Container>
        <PlanIntro />
        <CheckoutForm />
      </Container>
    </React.Fragment>
  );
}

export default CheckoutPage;
