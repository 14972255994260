import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export const Container = styled.div`
  .yearly-plan-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 40px;
    background: #121212;
    backdrop-filter: blur(100px);
    border-radius: 15px;
  }

  .buy-plan {
    background: #ffffff;
    border-radius: 12px;
    width: max-content;
  }

  .btn-container {
    display: flex;
    gap: 20px;
  }

  .buy-button {
    padding: 10px 24px;
    color: #000;
    background-color: #fff;
    cursor: pointer;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    border-radius: 12px;
    height: max-content;
  }

  .know-more {
    padding: 10px 24px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 12px;
    height: max-content;
  }

  .plan-price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .plan-price-container p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #b3b3b3;
  }
  .plan-price-container h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: #ffffff;
  }
  @media (max-width: 800px) {
    .yearly-plan-container {
      padding: 30px 20px;
    }

    .buy-button {
      padding: 10px 18px;
      font-size: 14px;
    }

    .know-more {
      padding: 10px 12px;
      font-size: 14px;
    }
    .plan-price-container p {
      font-size: 14px;
    }
    .plan-price-container h1 {
      font-size: 24px;
    }

    .checkout-step {
      display: none;
    }
  }
`;

export default function YearlyPlan() {
  let navigate = useNavigate();

  return (
    <Container>
      <div className="yearly-plan-container">
        <div className="plan-price-container">
          <p>View Yearly Plan for only</p>
          <h1>1549 INR</h1>
        </div>
        <div className="btn-container">
          <div
            className="buy-button"
            onClick={() => navigate("/checkout/yearly")}
          >
            <p>Upgrade Plan</p>
          </div>
        </div>
      </div>
    </Container>
  );
}
