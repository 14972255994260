import React from "react";
import styled from "styled-components";
import TopHeader from "../component/Header";

const Container = styled.div`
  height: 80vh;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    height: 100px;
    width: 140px;
  }

  h1 {
    font-family: "Futura Bk BT";
    margin-top: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #ffffff;
  }

  p {
    margin-top: 10px;
    font-family: "Futura Lt BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }

  .download-button {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 40%;
    padding: 15px 20px;
    border: 1px solid #e9e9e9;
    color: #ffffff;
    border-radius: 21px;
  }
  .download-button p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
  }

  @media (max-width: 800px) {
    width: 80%;
    margin: auto;
    .download-button {
      width: 80%;
      padding: 10px 20px;
    }

    .download-button p {
      font-size: 12px;
    }
  }
`;

export default function ThankYouPage() {
  return (
    <>
      <TopHeader />
      <Container>
        <img className="logo" src="/logo.png" alt="TradeBook logo" />
        <h1>Thank you</h1>
        <p>
          With TradeBook, you can 3x your profits and capital by trading with
          us. Don't wait any longer to take control of your trading career.
          Download TradeBook today and start seeing real results.
        </p>
        <div
          className="download-button"
          onClick={() => {
            const platform = window.navigator.platform.toLocaleLowerCase()

            if (platform.includes('mac') || platform.includes('ip')) {
              window.open(
                "https://apps.apple.com/in/app/tradebook-one-stop-solution/id1659276893",
                true
              )
            } else {
              window.open(
                "https://play.google.com/store/apps/details?id=com.we3.tradebook.app",
                true
              )

            }
          }
          }
        >
          <p>DOWNLOAD TRADEBOOK APP</p>
          <img src="/playstore.png" alt="tradebook play store" />&
          <img src="/apple.png" alt="tradebook app store" />
        </div>
      </Container>
    </>
  );
}
