import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import styled from "styled-components";

const TestimonialsCard = ({ data }) => {
  const colors = [
    "#008B7A",
    "#30691D",
    "#788F9B",
    "#689F38",
    "#C2185A",
    "#02569B",
    "#0098A9",
  ];

  return (
    <div className="testimonials-card">
      <div
        className="profile"
        style={{
          backgroundColor: colors[Math.floor(Math.random() * colors.length)],
        }}
      >
        {data.name[0]}
      </div>
      <label>{data.name}</label>
      <p>{data.shortReview}</p>
      <span>{data.review}</span>

      <div className="star-container">
        <svg class="star" viewBox="0 0 24 24">
          <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
        </svg>
        <svg class="star" viewBox="0 0 24 24">
          <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
        </svg>
        <svg class="star" viewBox="0 0 24 24">
          <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
        </svg>
        <svg class="star" viewBox="0 0 24 24">
          <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
        </svg>
        <svg class="star" viewBox="0 0 24 24">
          <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
        </svg>
      </div>
    </div>
  );
};

export const Container = styled.div`
  margin-top: 80px;
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #d8d8d8;
  }

  .testimonials-card-container {
    margin: auto;
    width: 80%;
    margin-top: 80px;
  }

  .testimonials-card-container li {
    display: flex;
    justify-content: center;
  }

  .testimonials-card {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    height: max-content;
    width: 250px;
    align-items: center;
    height: 350px;
    border: 1px solid #cbcbcb;
  }

  .profile {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background: #d9d9d9;
    color: #fff;
    font-size: 24px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  label {
    margin-top: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #7b7b7b;
  }

  p {
    margin-top: 14px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #cbcbcb;
  }
  span {
    margin-top: 14px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #c4c4c4;
  }
  .star-container {
    margin-top: 22px;
  }
  .star {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: gold;
  }

  .rating-container {
    display: flex;
    justify-content: space-around;
    background-color: #0f0f0f;
    padding: 40px 20px;
    width: 80%;
    margin: auto;
    margin-top: 120px;
    align-items: center;
  }
  .rating-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rating-content p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #e9e9e9;
  }

  .rating-content h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    color: #e9e9e9;
  }
  .rating-content span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #e9e9e9;
  }
  .star-rating-container {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 2rem;
    }
    p {
      width: 80%;
      font-size: 1rem;
    }
    img {
      margin-top: 10px;
    }

    .testimonials-card {
      width: 200px;
      height: 250px;
    }
    .profile {
      width: 40px;
      height: 40px;
      font-size: 14px;
    }

    label {
      margin-top: 12px;
      font-size: 12px;
    }

    p {
      margin-top: 10px;
      font-size: 12px;
      line-height: 120%;
    }
    span {
      margin-top: 10px;
      font-size: 12px;
      line-height: 100%;
    }

    .testimonials-card-container {
      margin-top: 20px;
      width: 100%;
    }

    .rating-content p {
      font-size: 12px;
    }
    .rating-content h1 {
      font-size: 32px;
      line-height: 120%;
    }
    .rating-content span {
      font-size: 10px;
      line-height: 100%;
    }

    .star-rating-container svg {
      width: 10px;
      margin-top: 0px;
    }

    .rating-container {
      margin: 0px 0px;
      margin-top: 80px;
      width: 90%;
    }
  }
`;

export default function Testimonials() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const usersReview = [
    {
      name: "Ashwamedh Nair",
      review:
        "The perfect platform for learning about the stock market and implementing it on the same application without any hassle...",
      shortReview: "Perfect platform for learning",
    },
    {
      name: "Krupa Bhatia",
      shortReview: "User friendly and good experience",
      review:
        "A great app with beautiful features and it is user friendly with such a great AI features (which suggest us to choose which stocks should buy and sell). Happy trading 💓",
    },
    {
      name: "Ambarish Kulkarni",
      shortReview: "Good experience and interface",
      review:
        "This app has a great user interface. There are lots of features to explore and it's really promising. Loved the app 👍",
    },
    {
      name: "Tamanna Shroff",
      review:
        "The app is extremely easy to use and has so many features, it makes trading extrenely simple.",
      shortReview: "Trading Extrenely Simple",

    },
    {
      name: "shrey vasani",
      shortReview:"Wonderful user interface",
      review:
        "Great app with a wonderful user interface. Very easy to use and highly informational.",
    },
    {
      name: "Pratham K",
      shortReview:"Extremely useful features",
      review:
        "The app has simple yet elegant design and features extremely useful features.",
    },
    {
      name: "Yash gupta",
      shortReview:"Awesome app",
      review: "Awesome app.. this app is perfect in term of trading..👍🏻👍🏻",
    },
    {
      name: "Sneha Prakash",
      review:
        "I like that this app also teaches us trading and increases our knowledge",
    },
    {
      name: "saksham",
      review:
        "I recently started using the Tradebook trading app and have been thoroughly impressed with its performance. The user interface is clean and intuitive, making it easy for me to navigate through different trade options and market data.",
    },
    {
      name: "Aahna Gathani",
      review:
        "Really well designed app and extremely user friendly interface !! Great one",
    },
  ];

  return (
    <Container>
      <h1>Testimonials</h1>

      <div className="testimonials-card-container">
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          autoPlaySpeed={3000}
          responsive={responsive}
          // autoPlay={true}
          infinite={true}
        >
          {usersReview.map((e) => (
            <TestimonialsCard data={e} />
          ))}
        </Carousel>
      </div>

      <div className="rating-container">
        <div className="rating-content">
          <p>SEBI Registered</p>
          <p>Research Analyst</p>
        </div>
        <div className="rating-content">
          <h1>4.8</h1>
          <div className="star-rating-container">
            <svg class="star" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
            </svg>
            <svg class="star" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
            </svg>
            <svg class="star" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
            </svg>
            <svg class="star" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
            </svg>
            <svg class="star" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.33 6.91.99-4.99 4.87 1.18 6.9-6.18-3.25-6.18 3.25 1.18-6.9-4.99-4.87 6.91-.99z" />
            </svg>
          </div>
          <span>based on 10,000 ratings</span>
        </div>
        <div className="rating-content">
          <p>50,000+</p>
          <p>Active Traders</p>
        </div>
      </div>
    </Container>
  );
}
