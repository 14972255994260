import React, { useEffect, useState } from "react";

export default function DownloadApplication() {
  const [link, setLink] = useState("");

  useEffect(() => {
    const platform = window.navigator.platform.toLocaleLowerCase();

    if (platform.includes("mac") || platform.includes("ip")) {
      setLink(
        "https://apps.apple.com/in/app/tradebook-one-stop-solution/id1659276893"
      );
      window.location.href =
        "https://apps.apple.com/in/app/tradebook-one-stop-solution/id1659276893";
    } else {
      setLink(
        "https://play.google.com/store/apps/details?id=com.we3.tradebook.app"
      );
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.we3.tradebook.app";
    }
    return () => {};
  }, []);

  return (
    <div style={{textAlign:"center",}}>
      <a href={link} style={{color:'white',textAlign:"center",}}>DownloadApplication</a>
    </div>
  );
}
