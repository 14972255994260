import React, { useState } from "react";
import styled from "styled-components";
import { AiFillCaretUp } from "react-icons/ai";

const stockPerformance = {
  headers: ["Company", "Reco Price", "Reco Date", "Gain%", "Duration"],
  data: [
    {
      stock: "MOREPENLAB",
      price: "Rs. 29",
      date: "Jun 21, 2022",
      gain: "105%",
      duration: "6 Months",
    },
    {
      stock: "PNB",
      price: "Rs. 31",
      date: "July 18, 2022",
      gain: "93%",
      duration: "5 Months",
    },
    {
      stock: "DCW",
      price: "Rs. 39",
      date: "Aug 10, 2022",
      gain: "41%",
      duration: "1 Months",
    },
    {
      stock: "ITC",
      price: "Rs. 250",
      date: "Mar 23, 2022",
      gain: "40%",
      duration: "6 Months",
    },
    {
      stock: "CLEAN",
      price: "Rs. 1539",
      date: "July 13, 2022",
      gain: "25%",
      duration: "2 Months",
    },
    {
      stock: "POLYPLEX",
      price: "Rs. 1966",
      date: "Jun 21, 2022",
      gain: "17%",
      duration: "1 Month",
    },
    {
      stock: "CIPLA",
      price: "Rs. 953",
      date: "July 13, 2022",
      gain: "15%",
      duration: "2 Months",
    },
  ],
};

const indexPerformance = {
  headers: ["Company", "Reco Price", "Reco Date", "Gain%"],
  data: [
    {
      stock: "BANKNIFTY",
      optiontwo: "42800 PE",
      price: "Rs. 350",
      date: "Jan 25, 2022",
      gain: "82%",
    },
    {
      stock: "BANKNIFTY",
      optiontwo: " 42600 CE",
      price: "Rs. 370",
      date: "Jan 23, 2022",
      gain: "32%",
    },
    {
      stock: "BANKNIFTY",
      optiontwo: " 43500 CE",
      price: "Rs. 61",
      date: "Dec 1, 2022",
      gain: "56%",
    },
    {
      stock: "NIFTY 17800 PE",
      price: "Rs. 52",
      date: "Jan 12, 2022",
      gain: "20%",
    },
    {
      stock: "NIFTY 17650 PE",
      price: "Rs. 102",
      date: "Jan 23, 2022",
      gain: "33%",
    },
    {
      stock: "NIFTY 17750 PE",
      price: "Rs. 77",
      date: "Jan 19, 2022",
      gain: "25%",
    },
    {
      stock: "NIFTY 17800 CE",
      price: "Rs. 125",
      date: "Jan 27, 2022",
      gain: "23%",
    },
  ],
};
export const Container = styled.div`
  width: 100%;
  margin-top: 80px;
  .tabs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid #5f5f5f;
    width: 80%;
    margin: auto;
  }
  .active-tab {
    font-family: "poppins";
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 200%;
    text-align: center;
    color: #ffffff;
    width: 40%;
    border-bottom: 4px solid #ffffff;
  }
  .inactive-tab {
    font-family: "poppins";
    font-style: normal;
    cursor: pointer;
    text-align: center;

    font-weight: 400;
    font-size: 32px;
    line-height: 200%;
    color: #686868;
    width: 40%;
  }

  .table-container {
    width: 80%;
    margin: auto;
    margin-top: 60px;
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    background: #f7f7f7;
    backdrop-filter: blur(25px);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 0px 20px;
  }
  .table-header p:first-child {
    width: 20%;
  }
  .table-header p {
    font-family: "Futura Hv BT";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 10px 0px;
    padding-bottom: 20px;
  }
  .table-body {
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(100px);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: -5px;
    padding: 0px 20px;
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0px;
    border-bottom: 0.6px solid #3d3d3d;
  }

  .table-body :last-child {
    border-bottom: 0px solid #3d3d3d;
  }

  .row-header {
    font-family: "poppins";
    font-style: normal;
    width: 20%;
    font-weight: 400;
    padding: 10px 0px;
    font-size: 24px;
    line-height: 29px;
    color: #eaeaea;
  }
  .row-growth {
    display: flex;
    align-items: center;
    font-family: "poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #00ff48;
  }
  .row-text {
    font-family: "poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 0px;
    color: #b9b9b9;
    text-align: start;
  }

  @media (max-width: 800px) {
    .tabs-container {
      width: 90%;
    }
    .active-tab {
      font-size: 16px;
      line-height: 300%;
      width: max-content;
    }
    .inactive-tab {
      font-size: 16px;
      width: max-content;
      line-height: 300%;
    }
    .table-container {
      width: 90%;
      margin-top: 40px;
    }
    .table-row {
      padding: 5px 0px;
    }

    .table-header p {
      font-size: 12px;
    }
    .row-header,
    .row-growth,
    .row-text {
      font-size: 10px;
    }
  }
`;

export default function StockIndexTable() {
  const [tabChange, settabChange] = useState(true);
  const [tableData, setTableData] = useState(stockPerformance);

  return (
    <Container>
      <div className="tabs-container">
        <p
          className={tabChange ? "active-tab" : "inactive-tab"}
          onClick={() => {
            setTableData(stockPerformance);
            settabChange(true);
          }}
        >
          Stock Performance
        </p>
        <p
          className={!tabChange ? "active-tab" : "inactive-tab"}
          onClick={() => {
            setTableData(indexPerformance);
            settabChange(false);
          }}
        >
          Index Performance
        </p>
      </div>
      <div className="table-container">
        <div className="table-header">
          {tableData.headers?.map((e) => (
            <p key={e}>{e}</p>
          ))}
        </div>
        <div className="table-body">
          {tableData.data?.map((e) => {
            return (
              <div className="table-row">
                <p className="row-header">{e.stock}</p>
                <p className="row-text">{e.price}</p>
                <p className="row-text">{e.date}</p>
                <p className="row-growth">
                  <AiFillCaretUp />
                  {e.gain}
                </p>
                {e.duration ? <p className="row-text">{e.duration}</p> : ""}
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}
