import React from "react";

import styled from "styled-components";

export const Container = styled.div`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #d8d8d8;
  }

  p {
    width: 60%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 180.87%;
    text-align: center;
    color: #cbcbcb;
  }

  img {
    margin-top: 60px;
    width: 90%;
    height: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 80px;
    gap: 10px;
    h1 {
      font-size: 2rem;
    }
    p {
      width: 80%;
      font-size: 1rem;
    }
    img {
      margin-top: 10px;
    }
  }
`;

export default function Mockup() {
  return (
    <Container>
      <h1>Why TradeBook?</h1>

      <p>
        Are you tired of watching others make profit while you are making losses
        ? Are you spending hours on end analyzing charts and data, only to see
        your investments fail? It's time to take control of your trading career
        and start seeing real results. Join one of the biggest trading
        communities with 50,000+ happy clients.
      </p>

      <img src="/mockups1.png" alt="mockup" />
    </Container>
  );
}
