import React, { useState } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export const OurPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  h1 {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    color: #d8d8d8;
  }
  .plan-title {
    margin-top: 40px;
    font-family: "Poppins";
    font-style: normal;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.03em;
    color: #cbcbcb;
  }

  /* plan-container */
  .plan-container {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .right-arrow {
    cursor: pointer;
    margin-left: -60px;
    width: 100px;
    height: 100px;
    background: #0a0a0a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inner-right-arrow {
    width: 70px;
    height: 70px;
    background: #121212;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inner-right-arrow img {
    height: 40%;
  }

  .small-plan-card {
    opacity: 0.2;
    height: max-content;
    margin-left: -20%;
    z-index: -10;
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 2rem;
    }
    .plan-title {
      font-size: 18px;
    }
    .plan-container {
      width: 80%;
      margin: auto;
      justify-content: space-around;
      margin-top: 50px;
    }
    .right-arrow {
      z-index: 10;
      margin-left: -20%;
      width: 0px;
      height: 0px;
      padding: 30px;
      border-radius: 50%;
    }
    .inner-right-arrow {
      width: 0px;
      height: 0px;
      padding: 20px;
      background: #121212;
      border-radius: 50%;
    }
    .inner-right-arrow img {
      height: 20px;
    }
    .small-plan-card {
      margin-left: -45%;
    }
  }
`;

export const PriceCardContainer = styled.div`
  .header {
    width: 100%;
    background: #f7f7f7;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .header p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    padding: 10px 20px;
    padding-bottom: 10px;
    font-size: 16px;
    text-align: center;
    color: #000000;
  }
  .plan-body {
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(20px);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
    margin-top: -1px;
    padding: 25px 65px;
  }

  .plan-header {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    padding-top: 20px;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
  }
  .price-container {
    margin-top: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    text-align: center;
    color: #f7f7f7;
  }
  .price-container s {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    text-decoration-line: line-through;
    color: #5a5a5a;
  }

  ul {
    margin-top: 40px;
    text-align: center;
    list-style: inside;
  }
  li {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 300%;
    color: #f7f7f7;
  }

  .button-container {
    margin-top: 60px;
    margin-bottom: 30px;
    gap: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .buy-button {
    cursor: pointer;
    padding: 20px 24px;
    width: 50%;
    text-align: center;
    color: #000;
    background-color: #fff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    border-radius: 12px;
  }

  .know-more {
    padding: 20px 24px;
    width: 50%;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    cursor: pointer;
    font-size: 18px;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 12px;
  }

  @media (max-width: 800px) {
    margin: 0px;
    .header p {
      font-size: 14px;
    }
    .plan-header {
      font-size: 16px;
      padding-top: 0px;
    }

    .plan-body {
      padding: 15px 30px;
    }
    .price-container {
      margin-top: 10px;
      font-size: 28px;
    }
    .price-container s {
      font-size: 18px;
    }
    ul {
      margin-top: 30px;
    }
    li {
      margin-top: 10px;
      font-size: 12px;
      line-height: 120%;
    }
    .button-container {
      margin-top: 40px;
      gap: 10px;
    }
    .buy-button {
      text-align: center;
      width: max-content;
      padding: 10px 15px;
      font-size: 12px;
    }

    .know-more {
      width: max-content;
      text-align: center;
      padding: 10px 10px;
      font-size: 12px;
    }
  }
`;

export default function OurPlans() {
  let navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);

  const buyPlanList = [
    {
      price: "299",
      planType: "Monthly Plan",
      cancelPrice: "499",
      featurePoint: [
        "5 AI Stock Report",
        "2 AI Portfolio",
        "TradeBunch access",
        "24x7 Whatsapp, Email & Call Support",
        "Daily Delivery Calls",
        "Index and Stock Options",
      ],
      navigate: () => {
        navigate("/checkout/monthly");
      },
    },
    {
      price: "1549",
      cancelPrice: "3600",
      planType: "Yearly Plan",
      featurePoint: [
        "25 AI Stock Report",
        "12 AI Portfolio",
        "TradeBunch access",
        "24x7 Whatsapp, Email & Call Support",
        "Daily Delivery Calls",
        "Index and Stock Options",
      ],
      navigate: () => {
        navigate("/checkout/yearly");
      },
    },
  ];

  return (
    <OurPlanContainer>
      <h1>Our Plans</h1>
      <p className="plan-title">
        A One-Stop Subscription to Elevate your Trading and Investing Journey
      </p>

      <div className="plan-container">
        <PriceCardContainer>
          <div className="header">
            <p>Reommended Best Value</p>
          </div>
          <div className="plan-body">
            <p className="plan-header">
              {buyPlanList[currentIndex]["planType"]}
            </p>

            <p className="price-container">
              ₹{buyPlanList[currentIndex]["price"]}{" "}
              <s>₹{buyPlanList[currentIndex]["cancelPrice"]}</s>
            </p>
            <ul>
              {buyPlanList[currentIndex]["featurePoint"].map((e) => {
                return <li>{e}</li>;
              })}
            </ul>
            <div className="button-container">
              {/* Add ICON */}
              <div
                className="buy-button"
                onClick={() => buyPlanList[currentIndex]["navigate"]()}
              >
                BUY PLAN
              </div>

              <div
                className="know-more"
                onClick={() => buyPlanList[currentIndex]["navigate"]()}
              >
                KNOW MORE
              </div>
            </div>
          </div>
        </PriceCardContainer>

        <div className="small-plan-card">
          <PriceCardContainer>
            <div className="header">
              <p>Reommended Best Value</p>
            </div>
            <div className="plan-body">
              <p className="plan-header">
                {buyPlanList[currentIndex == 1 ? 0 : 1]["planType"]}
              </p>

              <p className="price-container">
                ₹{buyPlanList[currentIndex == 1 ? 0 : 1]["price"]}{" "}
                <s>₹{buyPlanList[currentIndex == 1 ? 0 : 1]["cancelPrice"]}</s>
              </p>
              <ul>
                {buyPlanList[currentIndex == 1 ? 0 : 1]["featurePoint"].map(
                  (e) => {
                    return <li>{e}</li>;
                  }
                )}
              </ul>
              <div className="button-container">
                <div className="buy-button">BUY PLAN</div>

                <div className="know-more">KNOW MORE</div>
              </div>
            </div>
          </PriceCardContainer>
        </div>

        <div
          className="right-arrow"
          onClick={() => setCurrentIndex(currentIndex == 1 ? 0 : 1)}
        >
          <div className="inner-right-arrow">
            <img src="/rightArrow.png" alt="right arrow" />
          </div>
        </div>
      </div>
    </OurPlanContainer>
  );
}
